import { useForm, useFormState } from 'react-final-form';
import { useEffect, useMemo } from 'react';
import useEnrollmentForm from '../hooks/useEnrollmentForm';
const FIELDS = {
    EASY_ENROLLMENT: 'easyEnrollment',
    STANDARD_ENROLLMENT: 'standardEnrollment',
    SINGLE_PAIRING: 'singlePairing',
    RESET_GESTURE: 'resetGesture',
    ENROLLMENT_TYPE: 'options',
};
export const EnrollmentSection = ({ children }) => {
    const formState = useFormState();
    const form = useForm();
    const enrollmentType = useMemo(
        () => formState.values?.options,
        [formState]
    );
    const singlePairing = useMemo(
        () => formState.values?.singlePairing,
        [formState]
    );
    const {
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
        updateCredentialsDisabled,
    } = useEnrollmentForm({ enrollmentType, singlePairing });
    useEffect(() => {
        const { active } = formState;
        const activeValue = formState.values[active];
        if (
            [FIELDS.EASY_ENROLLMENT, FIELDS.STANDARD_ENROLLMENT].includes(
                active
            ) &&
            !singlePairing
        ) {
            form.change(
                active === FIELDS.EASY_ENROLLMENT
                    ? FIELDS.STANDARD_ENROLLMENT
                    : FIELDS.EASY_ENROLLMENT,
                !activeValue
            );
        } else if (active === FIELDS.SINGLE_PAIRING && activeValue) {
            form.change(FIELDS.STANDARD_ENROLLMENT, true);
            form.change(FIELDS.EASY_ENROLLMENT, true);
        }
    }, [formState, form, singlePairing]);
    useEffect(() => {
        if (enrollmentType) {
            form.batch(() => {
                form.change(FIELDS.EASY_ENROLLMENT, false);
                form.change(FIELDS.STANDARD_ENROLLMENT, false);
                form.change(FIELDS.SINGLE_PAIRING, false);
            });
        } else {
            form.change(FIELDS.RESET_GESTURE, false);
            form.change(FIELDS.ENROLLMENT_TYPE, null);
        }
    }, [enrollmentType, form]);
    return children({
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
        updateCredentialsDisabled,
    });
};
export default EnrollmentSection;
