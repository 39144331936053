import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import ImageInput from '../../components/ImageInput';
import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
    useTranslate,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import { useSelector } from 'react-redux';
import { getBrands, getSeries } from '../../selectors/cmsConfig';
import programsListPageApplianceType from '../../data/programsListPageApplianceType.json';
import programsListPageType from '../../data/programsListPageType.json';
import programsListPageStyle from '../../data/programsListPageStyle.json';
export const ProgramsListPageEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const isEditDisabled = !permissions?.['programs-list-page']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.programs-list-page.name'),
            url: 'programs-list-page',
            icon: <RowingIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                    redirect="list"
                >
                    <ContainerFieldsList>
                        <CustomSelectInput
                            label="Type"
                            source="type"
                            choices={[...programsListPageType]}
                            validate={required()}
                            disabled
                        />
                        <CustomSelectInput
                            source="applianceType"
                            choices={[...programsListPageApplianceType]}
                            validate={required()}
                            disabled
                        />
                        <CustomSelectInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                            validate={required()}
                            disabled
                        />
                        <CustomSelectArrayInput
                            choices={series}
                            label="Series"
                            source="series"
                            validate={required()}
                        />
                        <CustomSelectInput
                            label="Style"
                            source="styleInApp"
                            choices={[...programsListPageStyle]}
                            validate={required()}
                        />
                        <TextInput
                            label="Title"
                            source="title"
                            validate={required()}
                        />
                        <TextInput
                            label="Subtitle"
                            source="subtitle"
                            validate={required()}
                        />
                    </ContainerFieldsList>
                    <BooleanInput label="Active" source="isActive" />
                    <hr className={classes.width} />
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator>
                            <SimpleShowLayout>
                                <ImageField source="imagePath" label="" />
                            </SimpleShowLayout>
                            <TextInput
                                source="programName"
                                validate={required()}
                            />
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={<p>Drop your file here</p>}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default ProgramsListPageEdit;
